


function PlayLetterCrashSound(props,x){
    const damage = new Audio(`/sound/ogg/Letter_${x}.ogg`);
    damage.id='letterSound'
    damage.play();

damage.addEventListener('ended',function (){
props();
    })


}

export default PlayLetterCrashSound;