import styled, { css } from 'styled-components';



const DividerWrapper = styled.div`
  ${(propList) =>
    propList.mb &&
    css`
      margin-bottom: ${(props) => `${props.mb ?? 0}px;`};
    `};
  ${(propList) =>
    propList.mt &&
    css`
      margin-top: ${(props) => `${props.mt ?? 0}px;`};
    `};
  background-color: ${(props) => `${props.color ?? '#c1c1c1'};`};
  height: 1px;
  width: 100%;
`;

function Divider(props) {
    return <DividerWrapper {...props} />;
}

export default Divider;
