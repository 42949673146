import { connect } from 'react-redux';


import Hud from "./Hud";

const mapStateToProps = (state) => {
    return {
        interactData: state.interactReducer,

    };
};
const mapDispatchToProps = () => {
    return {


    };
};

const HudContainer = connect(mapStateToProps, mapDispatchToProps)(Hud);
export default HudContainer;
