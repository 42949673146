import {useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {useTrimesh} from "@react-three/cannon";
import * as THREE from 'three';
import {useFrame} from "@react-three/fiber";

useGLTF.preload('/track-draco.glb')

function AddMesh(props) {
    const {geometry, material, scale,name} = props;
    const config = {receiveShadow: true, castShadow: true, 'material-roughness': 1}
    const bird1 = useRef();
    const newMaterial = new THREE.MeshPhongMaterial({
        map: material.map
    })
    if(name === 'Plane005_ColorPalette_0'){
        newMaterial.castShadow = false;
    }

    useFrame((_,delta)=>{
        if(bird1.current){
            bird1.current.rotation.y += delta / 3.5
        }

    })
    const vertices = geometry.attributes.position.array;
    const indices = geometry.index.array;
    const [ref] = useTrimesh(
        () => ({
            args: [vertices, indices],
            mass: 0,
            type: "Static",
        }),
        useRef(null)
    );
   // Plane005_ColorPalette_0
    return <mesh scale={scale} ref={(name === 'Plane049_ColorPalette_0' || name === 'Plane050_ColorPalette_0'
        || name === 'Plane051_ColorPalette_0' || name === 'Plane059_ColorPalette_0') ? bird1 : ref} geometry={geometry} material={newMaterial} {...config} />

}

export function Track(props) {
    const {nodes} = useGLTF('/track-draco.glb')
    const {scale} = props

    const meshes = Object.entries(nodes);
    const test = meshes.map((e, index) => {

        if (e[1].type === 'Mesh') {
            return <AddMesh scale={scale} key={index} name={e[1].name} geometry={e[1].geometry}
                            material={e[1].material}/>
        }
    })
    return (
        <group  {...props} dispose={null}>
            {test}
        </group>
    )
}
