import {useBox} from "@react-three/cannon";


function TriggerBox(props) {

    const {isMobile} = props;
    const [ref, api] = useBox(() => ({
        mass: 0.1,
        position: props.position,
        rotation: props.rotation,
        args: [0.5, 0.5, 0.5],

        onCollide: (e) => {
            if (e.body.name === 'car') {
                if (props.number === 0) {
                    if (!isMobile) {
                        props.setModal(-window.innerHeight / 2.4, 0, 'auto');
                    }
                    api.position.set(props.position.x, 5, props.position.z)
                    props.setActiveBillboard(0);
                }
                if (props.number === 1) {
                    if (!isMobile) {
                        props.setModal(-window.innerHeight / 2.4, 1, 'auto');
                    }
                    api.position.set(props.position.x, 5, props.position.z)
                    props.setActiveBillboard(1);
                }
                if (props.number === 2) {
                    if (!isMobile) {
                        props.setModal(-window.innerHeight / 2.4, 2, 'auto');
                    }
                    api.position.set(props.position.x, 5, props.position.z)
                    props.setActiveBillboard(2);

                }


            }
        },

    }));


    return (
        <>

            <mesh ref={ref} receiveShadow={true} castShadow={true}>

            </mesh>

        </>
    )
}

export default TriggerBox
