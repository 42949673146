import GamePage from "../GamePage";
import AvatarPageContainer from "../UiElements/AvatarPage/AvatarPageContainer";
import HudContainer from "../UiElements/Hud/HudContainer";
import ModalWindowContainer from "../UiElements/ModalWindow/ModalWindowContainer";
import FinalModalWindowContainer from "../UiElements/FinalModalWindow/FinalModalWindowContainer";



function App() {

    return (
        <>
                <FinalModalWindowContainer/>
            <ModalWindowContainer />
            <HudContainer />
            <AvatarPageContainer/>
            <GamePage/>
            <audio id={'roadSound'}  src={'/sound/ogg/road_loop.ogg'}/>
            <audio id={'engineStartSound'}   src={'/sound/ogg/engine_start.ogg'}/>
            <audio id={'lightOffSound'}   src={'/sound/ogg/Light_on.ogg'}/>
            <audio id={'lightOnSound'}   src={'/sound/ogg/Light_off.ogg'}/>
            <audio id={'ambianceSound'} loop={true}   src={'/sound/ogg/desert_ambiance.ogg'}/>
            <audio id={'tyresSound'}   src={'/sound/ogg/tyres_scratch_1.ogg'}/>
            <audio id={'tyresSoundTwo'}   src={'/sound/ogg/tyres_scratch_2.ogg'}/>
            <audio id={'tyresSoundThree'}   src={'/sound/ogg/tyres_scratch_3.ogg'}/>
            <audio id={'gearUpSound'}   src={'/sound/ogg/gear_up.ogg'}/>
            <audio id={'gearDownSound'}   src={'/sound/ogg/gear_down.ogg'}/>
            <audio id={'hornSound'}   src={'/sound/ogg/Horn.ogg'}/>
            <audio id={'eaglesAmb'} loop={true}  src={'/sound/ogg/EaglesAmb.ogg'}/>

        </>
    )
}

export default App;
