import {useEffect, useMemo, useState} from "react";
import {Vector3} from "three";
import {useFrame} from "@react-three/fiber";
import {PitchShifter} from 'soundtouchjs';
import PlayTyresSound from "./components/PlayTyresSound";

const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
const gainNode = audioCtx.createGain();
let pitchUp = 0.1;
let stepUp = 0;
let volume = 0.7;
let moveStarted = false;
let engineSoundNum = -5;
let brakesPlayed = false;
let arrNumber = 0;
export const useControls = (vehicleApi, chassisApi, scene, chassisBody, setHeadlightToggle, headlightToggle,stopLight,setStopLight,setFinalModal,setModal, billboardData, controls, setControls) => {
    const worldPosition = useMemo(() => new Vector3(), [])
    const [shifter, setShifter] = useState();

    const [play, setPlay] = useState(true);
    const [direction, setDirection] = useState('none');
    const [redirect, setRedirect] = useState(true);

    const newShifter = (buffer) => {
        const myShifter = new PitchShifter(audioCtx, buffer, 16384);
        myShifter.pitch = 1.0;
        myShifter.on('play', (e) => {
            if (Math.trunc(e.percentagePlayed) > 90) {
                myShifter.percentagePlayed = 0;
                audioCtx.resume();
            }
        })
        setShifter(myShifter);
    };
    document.querySelector('#roadSound').loop = true;

let started = false
    const titleArr = [
        'Техномедиа 🌵⛽️🗻🚐',
        'Техномедиа 🌵⛽️🚐🚧',
        'Техномедиа 🌵🚐🗻🚧',
        'Техномедиа 🚐⛽️🗻🚧',



    ]


    useFrame(() => {
        arrNumber+=0.01;

        document.title = titleArr[Math.round(arrNumber)];
if(Math.round(arrNumber) === 4){
    arrNumber = 0;
}
        chassisBody.current.getWorldPosition(worldPosition)
        const distance = worldPosition.distanceTo(chassisBody.current.position)
        chassisBody.current.position.lerp(worldPosition, 0.1);


        if((distance * 3) < 10 && (distance * 3) > 0.1){
          document.querySelector('#roadSound').playbackRate = (distance * 3);
            document.querySelector('#roadSound').play();
        } else {
            document.querySelector('#roadSound').pause();
        }


        if (shifter) {
            shifter.pitchSemitones = -2
            if(moveStarted && pitchUp > 0){
                if(started && pitchUp < 5){
                    volume += 0.003
                    pitchUp += 0.05
                    gainNode.gain.value = volume
                    shifter.pitchSemitones = -2 + pitchUp;
                }
                if(started && pitchUp >= 5) {
                    document.querySelector('#gearUpSound').play();
                    started = false
                    pitchUp = -1;
                }
            }
            if(moveStarted && pitchUp < 0 && distance > 0.05) {
                shifter.pitchSemitones = -2 + stepUp;
                gainNode.gain.value = volume;
                volume += 0.0008;
                stepUp += 0.01;
                if(stepUp > 6){
                    document.querySelector('#gearUpSound').play();

                    stepUp = 0;
                    setTimeout(()=>{  volume = 0.7;},500)

                }
            }

        }
    });

    document.addEventListener('visibilitychange', function () {
        if (document.hidden) {
            gainNode.gain.value = 0;
            document.querySelector('#ambianceSound').muted = true;
            document.querySelector('#eaglesAmb').muted = true;
            document.querySelector('#roadSound').muted = true;
        } else {
            gainNode.gain.value = 1;
            document.querySelector('#ambianceSound').muted = false;
            document.querySelector('#eaglesAmb').muted = false;
            document.querySelector('#roadSound').muted = false;
        }
    });

    const keyDownPressHandler = (e) => {
        if(e.key.toLowerCase() === 'ц'){
            setControls((controls) => ({
                ...controls,
                ['w']: true
            }));
        }
        if(e.key.toLowerCase() === 'к'){
            setControls((controls) => ({
                ...controls,
                ['r']: true,
            }));
        }
        if(e.key.toLowerCase() === 'ы'){
            setControls((controls) => ({
                ...controls,
                ['s']: true
            }));
        }
        if(e.key.toLowerCase() === 'ф'){
            setControls((controls) => ({
                ...controls,
                ['a']: true
            }));
        }
        if(e.key.toLowerCase() === 'в'){
            setControls((controls) => ({
                ...controls,
                ['d']: true
            }));
        }
        if(e.key.toLowerCase() === 'д'){
            setControls((controls) => ({
                ...controls,
                ['l']: true
            }));
        }
        if(e.key.toLowerCase() === 'р'){
            setControls((controls) => ({
                ...controls,
                ['h']: true
            }));
        }
        if (e.code === 'Space') {
            setControls((controls) => ({
                ...controls,
                ['Space']: true
            }));
        } else {
            setControls((controls) => ({
                ...controls,
                [e.key.toLowerCase()]: true
            }));
        }

    }
    const keyUpPressHandler = (e) => {
        if(e.key.toLowerCase() === 'ц'){
            setControls((controls) => ({
                ...controls,
                ['w']: false
            }));
        }
        if(e.key.toLowerCase() === 'ы'){
            setControls((controls) => ({
                ...controls,
                ['s']: false
            }));
        }
        if(e.key.toLowerCase() === 'к'){
            setControls((controls) => ({
                ...controls,
                ['r']: false,
            }));
        }
        if(e.key.toLowerCase() === 'ф'){
            setControls((controls) => ({
                ...controls,
                ['a']: false
            }));
        }
        if(e.key.toLowerCase() === 'д'){
            setControls((controls) => ({
                ...controls,
                ['l']: false
            }));
        }
        if(e.key.toLowerCase() === 'р'){
            setControls((controls) => ({
                ...controls,
                ['h']: false
            }));
        }
        if(e.key.toLowerCase() === 'в'){
            setControls((controls) => ({
                ...controls,
                ['d']: false
            }));
        }
        if (e.code === 'Space') {
            brakesPlayed = false;
            setControls((controls) => ({
                ...controls,
                ['Space']: false
            }));
        } else {
            setControls((controls) => ({
                ...controls,
                [e.key.toLowerCase()]: false
            }));
        }
    }
    useEffect(() => {
        fetch("/sound/wav/new_engine_loop_1.wav")
            .then((response) => {
                return response.arrayBuffer()
            }).then((buffer) => audioCtx.decodeAudioData(buffer)).then((decodedData) => {
            newShifter(decodedData)
        });

        window.addEventListener("keydown", keyDownPressHandler);
        window.addEventListener("keyup", keyUpPressHandler);

    }, []);


    for (let b = 2; b < 4; b++) vehicleApi.setBrake(controls.Space ? 50 : 0, b)
    document.querySelector('#gearDownSound').volume = 0.3;
    document.querySelector('#gearUpSound').volume = 0.3;
    useEffect(() => {
        if (controls.h) {
            document.querySelector('#hornSound').play();
        }
        if (controls.l) {
            if (headlightToggle === 0) {
                setHeadlightToggle(1);
                document.querySelector('#lightOnSound').play();
            } else {
                setHeadlightToggle(0);
                document.querySelector('#lightOffSound').play();
            }

        }
        /*if(controls.Space){
            console.log(stopLight);
            if(stopLight === 1){
                setStopLight(5);
            }
        } else {
            if(stopLight ===5){
                setStopLight(1)
            }

        }*/
        if (controls.Space && !brakesPlayed) {

            PlayTyresSound();
            brakesPlayed = true;
        }

        if (controls.r) {
            vehicleApi.applyEngineForce(0, 2);
let lastBillboard = 'null'
            for(let i = 0; i < billboardData.activeBillboard.length; i++){
                if(billboardData.activeBillboard[i] === true){
                    lastBillboard = i;
                }
            }
          if(lastBillboard === 0){
              chassisApi.position.set(1, 0.2, 6.4)
              chassisApi.rotation.set(0, -Math.PI / 2.7, 0)
          } else if(lastBillboard === 1){
                chassisApi.position.set(5, 0.3, 0.4)
                chassisApi.rotation.set(0, 0, 0)
            } else if(lastBillboard === 2){
              chassisApi.position.set(-3.5, 0.3, 2)
              chassisApi.rotation.set(0, 0, 0)
          } else if(lastBillboard === 3){
              chassisApi.position.set(-1.18, 0.1, 0.15)
              chassisApi.rotation.set(0, -Math.PI / 2, 0)
          } else if(lastBillboard === 4){
              chassisApi.position.set(-2.8, 0.1, -5.9)
              chassisApi.rotation.set(0, Math.PI / 2.7, 0)
          }
          else {
              chassisApi.position.set(-3, 0.1, 8.4)

              chassisApi.rotation.set( 0, -Math.PI / 2.7, 0);
          }
            chassisApi.velocity.set(0, 0, 0);
            chassisApi.angularVelocity.set(0, 0.5, 0);
        }

        if (controls.w) {
            started = true;
            if(!moveStarted){
                engineSoundNum = 6;

                moveStarted=true;
            }
            if (direction === 'back') {
                setDirection('forward');
                document.querySelector('#gearUpSound').play();

            }
            if (scene.getObjectByName('controls')) {
                scene.remove(scene.getObjectByName('controls'));
            }
            if (play) {
                document.querySelector('#engineStartSound').play();
                document.querySelector('#ambianceSound').play();
                document.querySelector('#ambianceSound').loop = true;
                document.querySelector('#ambianceSound').muted = false;
                document.querySelector('#eaglesAmb').play();
                document.querySelector('#eaglesAmb').muted = false;
                document.querySelector('#eaglesAmb').loop = true;

                shifter.connect(gainNode);
                gainNode.connect(audioCtx.destination);
                audioCtx.loop = true;
                audioCtx.resume();
                setPlay(false);
            }

            vehicleApi.applyEngineForce(1700, 2);


        } else if (controls.s) {
            setDirection('back');
            if (direction === 'forward') {
                document.querySelector('#gearDownSound').play();

            }
            vehicleApi.applyEngineForce(-1500, 2);

        } else {
            moveStarted = false;
            pitchUp = 0.1;
            stepUp = 0;
            volume = 0.7;
            gainNode.gain.value = volume
            vehicleApi.applyEngineForce(0, 2);

        }
        if (controls.a) {
            vehicleApi.setSteeringValue(0.4, 2);
            vehicleApi.setSteeringValue(0.4, 3);

        } else if (controls.d) {
            vehicleApi.setSteeringValue(-0.5, 2);
            vehicleApi.setSteeringValue(-0.5, 3);

        } else {
            for (let i = 0; i < 4; i++) {
                vehicleApi.setSteeringValue(0, i);
            }
        }
        if (chassisBody.current.position.z < -7.4 && chassisBody.current.position.x < -3.9) {
            if (redirect) {
setFinalModal('50');
                setModal('-100', 4, 'auto');
                setRedirect(false);
            }

        } else {
            if(!redirect){
                setRedirect(true);
            }
        }
    }, [controls, vehicleApi, chassisApi, shifter]);

    return controls;
}
