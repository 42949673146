import styled from "styled-components";
import Divider from "../Divider/Divider";



const ModalLeftWrapper = styled.div`
  position: absolute;
  top: auto;
  bottom: Calc(${props => props.bottom}% - ${props => props.height/2}px);
  left: Calc(50% - ${props => props.height}px);
  right: auto;
  z-index: 999999;
  font-family: 'Roboto', sans-serif;



  transition: 900ms;

`
const ModalContent = styled.div`
  position: absolute;
left:10%;
  top:4%;

`
const Content = styled.div`
  background: url("/background-min.PNG");
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  text-align: center;

  .notificationWrapper {
    top: Calc(50% - ${props => props.height/4}px);
    left: Calc(50% - ${props => props.width/4}px);
    max-width:  ${props => props.width/2}px;
    line-height: 20px;
    position: absolute;
    border-radius: 15px;
    background-color: #f0f0f0;
    z-index: 99999;
  }

  .textWrapper {
    padding: 15px;
  }

  .title {
    padding-top: 5px;
    font-weight: bold;
    padding-bottom: 15px;
  }

  .buttonsWrapper {

    color: #0065ff;
    font-weight: bold;
    padding: 20px;
    display: grid;
    cursor: pointer;
    grid-template-columns: 1fr 1fr;
  }
`

function FinalModalWindow(props) {
    const body = document.body,
        html = document.documentElement;
    const width = Math.max(body.scrollWidth, body.offsetWidth,
        html.clientWidth, html.scrollWidth, html.offsetWidth);
    //props.interactData.showFinalModal
let count = document.querySelector('#letterCount') ? document.querySelector('#letterCount').innerHTML:1;

    return (
        <>
            <ModalLeftWrapper
                onClick={() => {
                    props.setFinalModal('-100')
                }} bottom={props.interactData.showFinalModal} height={width / 3.4}>
                <ModalContent>
                    <Content height={width / 3.53} width={width / 2}>
                        <div className='notificationWrapper'>

                            <div className='textWrapper'>
                                <div className='title'>
                                    {props.interactData.finalVersion === 'first' && (<>Почти закончили!</>)}
                                    {props.interactData.finalVersion === 'second' && (<>Поздравляем!</>)}
                                </div>
                                {props.interactData.finalVersion === 'first' && (<> Соберите все буквы в мини-игре и получите
                                    промокод на скидку 5% на ваш проект!</>)}
                                {props.interactData.finalVersion === 'second' && (<> Вы сбили все буквы и получаете промокод на
                                    скидку 5% на ваш проект!<br/>
                                    Промокод: VEZDEHOD</>)}
                            </div>
                            <Divider/>
                            <div className='buttonsWrapper'>
                                {props.interactData.finalVersion === 'first' && (<>
                                    <div onClick={() => {
                                        props.setFinalModal('-100')
                                    }}>Остаться
                                    </div>
                                    <div onClick={() => {
                                        window.ym(95195328, 'reachGoal', 'endgame')
                                        window.open(
                                            'https://sysdyn.ru/portfolio',
                                            '_blank'
                                        );
                                    }} style={{color: 'red'}}>Перейти
                                    </div>
                                </>)}
                                {props.interactData.finalVersion === 'second' && (<>
                                    <div onClick={() => {
                                        navigator.clipboard.writeText('VEZDEHOD')
                                    }}>Скопировать
                                    </div>
                                    <div onClick={() => {
                                        window.ym(95195328, 'reachGoal', 'endgame')
                                        window.open(
                                            'https://sysdyn.ru/portfolio',
                                            '_blank'
                                        );
                                    }} style={{color: 'red'}}>Перейти</div>
                                </>)}
                            </div>
                        </div>
                    </Content>
                </ModalContent>
                <img alt={'phone image'} width={width/1.6} src={'/phoneV.png'}/>
            </ModalLeftWrapper>
        </>
    )
}

export default FinalModalWindow;