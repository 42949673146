import { connect } from 'react-redux';
import FinalModalWindow from "./FinalModalWindow";
import {setShowFinalModal} from "../../reducers/interacReducer";

const mapStateToProps = (state) => {
    return {
        interactData: state.interactReducer,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setFinalModal: (data) => {
            dispatch(setShowFinalModal(data));
        }

    };
};

const FinalModalWindowContainer = connect(mapStateToProps, mapDispatchToProps)(FinalModalWindow);
export default FinalModalWindowContainer;
