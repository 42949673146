import {useBox, useRaycastVehicle} from "@react-three/cannon";
import {useFrame, useLoader, useThree} from "@react-three/fiber";
import {useState} from "react";
import {useControls} from "../../useControls";
import {useWheels} from "../../useWheels";
import {WheelDebug} from "../../WheelDebug";
import * as THREE from "three";
import {TextureLoader} from "three";
import PlayCrashSound from "../../components/PlayCrushSound";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import PlayLetterCrashSound from "../../components/PlayLetterCrushSound";
import {Wheel} from "../../Wheel";



let canPlayLetterSound = true;
let x = 1;
let y = 1;
let count = 0;
let carXPos=0;
let carZPos=0;

function setCanPlay() {
    canPlayLetterSound = true;
}

export function Car(props) {
    const scene = useThree((state) => state.scene);
    const chassisBodyArgs = [0.20, 0.07, 0.20 * 2];
    const {controls,setControls,isMobile} = props;
    const cameraPosition = new THREE.Vector3();
    const [headlightToggle, setHeadlightToggle] = useState(0);
    let crushPlay = true;
    const [chassisBody, chassisApi] = useBox(
        () => ({
            args: chassisBodyArgs,
            mass: 3080,
            //position: [-3.50,0.1,-3],
            position: [-3, 0.1, 8.4],
            rotation: [0, -Math.PI / 2.7, 0],
            type:'Dynamic',

            onCollide: (e) => {
                if (!e.body.children[0]) return
                if (e.body.children[0].name !== 'letter') {

                    if(carXPos === String(chassisBody.current.position.x).substring(0,5) && carXPos === String(chassisBody.current.position.x).substring(0,5)){
                        crushPlay = false
                    } else {
                        crushPlay = true;
                    }
                    if (crushPlay) {
                        props.setResetTip(2);
                        PlayCrashSound();
                        crushPlay = false;
                    }
                 /*   setTimeout(() => {waa
                        crushPlay = true
                    }, 3000)*/
                    setTimeout(() => {
                        props.setResetTip(-100)
                    }, 5000)
                }
                if (e.body.children[0].name === 'letter') {

                        PlayLetterCrashSound(setCanPlay, x);
                        count = 300;
                        x++;
                        if (x === 6) {
                            x = 1;
                        }

                }
                carXPos = String(chassisBody.current.position.x).substring(0,5);
                carZPos = String(chassisBody.current.position.z).substring(0,5);
            }
        }),
    );

    const [wheels, wheelInfos] = useWheels(0.09, 0.04, 0.10, 0.04);
    const [vehicle, vehicleApi] = useRaycastVehicle(
        () => ({
            chassisBody,
            wheels,
            wheelInfos,
            indexForwardAxis: 1,
        })
    );

    const garment = useLoader(
        GLTFLoader,
        "/models/BuhankaF1_4.glb"
    );
    const mesh = garment.scene;
    mesh.scale.set(4, 4, 4);
    mesh.castShadow = true
    mesh.name = 'car';
    mesh.traverse((item) => {
        item.castShadow = true;
        item.receiveShadow = true;
    })
    const [stopLight,setStopLight] = useState(1);

    useControls(vehicleApi, chassisApi, scene, chassisBody, setHeadlightToggle, headlightToggle,  stopLight,setStopLight,props.setFinalModal,props.setModal, props.billboardData, controls, setControls);
    const lightTargetGeometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({color: 0x00ff00});
    const lightTarget = new THREE.Mesh(lightTargetGeometry, material);
/*    const stopLightTarget = new THREE.Mesh(lightTargetGeometry, material);*/

    useFrame((state) => {
        if (count > 0) {
            count--;
        } else {
            x = 1;
            y = 1;
        }
        state.scene.updateMatrixWorld(true);
        cameraPosition.setFromMatrixPosition(mesh.matrixWorld);
        state.camera.position.x = cameraPosition.x - 3;
        state.camera.position.z = cameraPosition.z + 3;
        state.camera.position.y = 3.9;
        state.camera.lookAt(cameraPosition);

    })
    const map = new TextureLoader().load('/textures/headlight.jpeg');
    map.repeat.set(0.5, 0.5)


    return  <>
    <group ref={vehicle} name="vehicle">
        <mesh name='car' ref={chassisBody}>
            <primitive object={mesh} position={[0, 0, -0.04]}/>
            <primitive visible={false} position={[0, 0, -1]} object={lightTarget}/>
{/*<primitive visible={false} position={[0, 0, 0.4]} object={stopLightTarget}/>*/}

            <>
          {/*<spotLight color={'red'} intensity={stopLight} map={map} angle={Math.PI /8} distance={1} penumbra={0.1}
                           position={[0, 0.2, 0.05]}
                           castShadow={true} target={stopLightTarget}/>*/}
          {!isMobile && ( <><spotLight intensity={headlightToggle} map={map} angle={Math.PI / 6} distance={5} penumbra={0.1}
                           position={[-0.07, 0.4, -0.1]}
                           castShadow={true} target={lightTarget}/>
                <spotLight intensity={headlightToggle} map={map} angle={Math.PI / 6} distance={5} penumbra={0.1}
                           position={[0.07, 0.4, -0.1]}
                           castShadow={true} target={lightTarget}/></> )
            }
            </>

        </mesh>
        <Wheel ref={wheels[0]} radius={1} leftSide number={0}/>
        <Wheel ref={wheels[1]} radius={1} number={1}/>
        <Wheel ref={wheels[2]} radius={1} leftSide number={2}/>
        <Wheel ref={wheels[3]} radius={1} number={3}/>
        <WheelDebug wheelRef={wheels[0]} radius={0.1}/>
        <WheelDebug wheelRef={wheels[1]} radius={0.1}/>
        <WheelDebug wheelRef={wheels[2]} radius={0.1}/>
        <WheelDebug wheelRef={wheels[3]} radius={0.1}/>
    </group>

    </>
}
