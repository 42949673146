import "./index.css";
import { createRoot } from "react-dom/client";
import {StrictMode} from "react";

import store from "./store/store";
import {Provider} from "react-redux";
import AppContainer from "./App/AppContainer";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
    <StrictMode>
        <AppContainer />
    </StrictMode>
    </Provider>
);
