import { connect } from 'react-redux';


import {setShowModal} from "../../reducers/interacReducer";
import TriggerBox from "./TriggerBox";
import {setActiveBillboard} from "../../reducers/billboardReducer";


const mapStateToProps = (state) => {
    return {
        interactData: state.interactReducer,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveBillboard: (data) => {
            dispatch(setActiveBillboard(data));
        },
        setModal: (left,linkNumber,right) => {
            dispatch(setShowModal(left,linkNumber,right));
        },

    };
};

const TriggerBoxContainer = connect(mapStateToProps, mapDispatchToProps)(TriggerBox);
export default TriggerBoxContainer;
