import {Canvas} from "@react-three/fiber";
import {Physics} from "@react-three/cannon";
import {Html, PerspectiveCamera, Stats, useProgress} from "@react-three/drei";
import {Suspense, useEffect, useState} from "react";
import styled from "styled-components";
import {Ground} from "./SceneElements/Ground";
import {Track} from "./SceneElements/AddTrack";
import CarContainer from "./SceneElements/Car/CarContainer";
import AddLightContainer from "./SceneElements/AddLight/AddLightContainer";
import {ColliderBox} from "./ColliderBox";
import {ColliderSphere} from "./ColliderSphere";
import {ThreeTextScene} from "./SceneElements/ThreeTextScene";
import {BillboardScene} from "./SceneElements/BillboardScene";
import TriggerBoxContainer from "./SceneElements/TriggerBox/TriggerBoxContainer";
import {isMobile} from "react-device-detect";
import {Leva, useControls} from "leva";
import {ReactComponent as ForwardIcon} from './UiElements/icons/forward.svg';
import {ReactComponent as LeftIcon} from './UiElements/icons/left.svg';
import {ReactComponent as BackwardIcon} from './UiElements/icons/backward.svg';
import {ReactComponent as RightIcon} from './UiElements/icons/right.svg';


const Wrapped = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  font-size: 14px;
  color: #F1F1F1;


  .background {
    background-image: url("/background-min.PNG");
    filter: blur(8px);
    -webkit-filter: blur(8px);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .loader {
    white-space: nowrap;
    position: absolute;
    bottom: -15%;
    left: 50%;
    width: 600px;
    height: 400px;
    margin: -200px 0 0 -300px;
  }

  .logo {
    position: absolute;
    background: rgba(61, 61, 61, 0.7);
    border: 1px solid white;
    border-radius: 20px;
    padding: 50px 20px 50px 20px;
    top: 30%;
    font-size: 80px;

    font-weight: 100;
    font-family: 'Roboto Thin', sans-serif;
    left: Calc(50% + 100px);

    margin: -200px 0 0 -300px;
    @media (max-width: 1441px) {
      font-size: 60px;

    }
  }

  .logoContent {
    text-align: center
  }
`

/*const ProgressBarModules = styled.div`
  width: 621px;
  margin-top: 9px;
  border-radius: 8px;

  height: 16px;
  background-color: #f5f5f5;
`;
const ProgressLabelModules = styled.div`
  width: ${(props) => `${props.percent * 100}%`};
  background-color: #33cccc;
  text-align: center;
  border-radius: 8px;
  height: 16px;
  color: black;
`;*/
const PictureModule = styled.div`
  width: ${(props) => `${props.percent}%`};
  text-align: right;
  transition: 150ms;
  margin-bottom: -4%;

  img {
    width: 150px;
  }

`;
const Controls = styled.div`
  width: 25%;
  position: absolute;
  z-index: 99999;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  left: 50%;`
const LoaderProgress = styled.div`
  width: 621px;
  margin-top: 9px;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  background: rgba(61, 61, 61, 0.7);

  .items {
    font-size: 18px;
    text-align: center
  }
`

function LoaderScreen() {
    const {active, progress, errors, item, loaded, total} = useProgress();
    const [text, setText] = useState('Прикручиваем колеса')
    const [percent, setPercent] = useState(0);
    const LoaderText = [
        '🚦Прикручиваем колеса',
        '🚧Проверяем масло',
        '🚦Заливаем бензин',
        '🚦Прогреваем двигатель',
        '🚦Строим маршрут',
        '⤴️Выезжаем'
    ]
    useEffect(() => {
        if (loaded < 7) {
            setText(LoaderText[0])
            setPercent(10);
        }
        if (loaded > 6 && loaded < 10) {
            setText(LoaderText[1])
            setPercent(20);
        }
        if (loaded > 9 && loaded < 13) {
            setText(LoaderText[2])
            setPercent(40);
        }
        if (loaded > 12 && loaded < 15) {
            setText(LoaderText[3])
            setPercent(60);
        }
        if (loaded > 14 && loaded < 19) {
            setText(LoaderText[4])
            setPercent(80);
        }
        if (loaded > 18 && loaded < 23) {
            setText(LoaderText[5]);
            setPercent(100);
        }
    }, [loaded])

    return <Html as='div'
                 center
                 style={{
                     width: 100,
                     height: 100,
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     flexDirection: "column"
                 }}><Wrapped>
        <div className="background"></div>
        <div className="logo">
            <div className='logoContent'>
                <div>Т Е Х Н О</div>
                <div>М Е Д И А</div>
                <div>🗻 🚐  &#127797;</div>
            </div>
        </div>
        <div className="loader">

            <PictureModule percent={percent}><img alt={'loader'} src={'/loader.gif'}/></PictureModule>
            <LoaderProgress>
                <div className='items'>{text}</div>
            </LoaderProgress>
            {/* <ProgressBarModules>
                <ProgressLabelModules
                    percent={progress / 100}
                >{Math.trunc(progress)}%</ProgressLabelModules>
            </ProgressBarModules>*/}

        </div>
    </Wrapped></Html>
}

function GamePage() {
    const {noise, bloom, shadows, antialias, PixelRatio} = useControls('Render Settings', {

        shadows: !isMobile,
        PixelRatio: {
            w: 0.9,
            h: 0.9
        }
    })
    const [controls, setControls] = useState({});
    const queryParameters = new URLSearchParams(window.location.search);
    console.log(queryParameters.get('debug'))
    if (queryParameters.get('debug') === true) {
        console.log('Debug Mode');
    }
    useEffect(() => {
        if (document.getElementById('ForwardButton')) {
            document.getElementById('ForwardButton').addEventListener('touchstart', function () {
                setControls((controls) => ({
                    ...controls,
                    ['w']: true
                }));
            });
            document.getElementById('ForwardButton').addEventListener('touchend', function () {
                console.log(123)
                setControls((controls) => ({
                    ...controls,
                    ['w']: false
                }));
            });
            if (document.getElementById('BackwardButton')) {
                document.getElementById('BackwardButton').addEventListener('touchstart', function () {
                    setControls((controls) => ({
                        ...controls,
                        ['s']: true
                    }));
                });
                document.getElementById('BackwardButton').addEventListener('touchend', function () {
                    console.log(123)
                    setControls((controls) => ({
                        ...controls,
                        ['s']: false
                    }));
                });
            }

            if (document.getElementById('LeftButton')) {
                document.getElementById('LeftButton').addEventListener('touchstart', function () {
                    setControls((controls) => ({
                        ...controls,
                        ['a']: true
                    }));
                });
                document.getElementById('LeftButton').addEventListener('touchend', function () {
                    console.log(123)
                    setControls((controls) => ({
                        ...controls,
                        ['a']: false
                    }));
                });
            }

            if (document.getElementById('RightButton')) {
                document.getElementById('RightButton').addEventListener('touchstart', function () {
                    setControls((controls) => ({
                        ...controls,
                        ['d']: true
                    }));
                });
                document.getElementById('RightButton').addEventListener('touchend', function () {
                    console.log(123)
                    setControls((controls) => ({
                        ...controls,
                        ['d']: false
                    }));
                });
            }
        }

    }, [document.getElementById('ForwardButton')])
    return (
        <>

            {isMobile && (
                <Controls onContextMenu={(e) => e.preventDefault()}>
                    <div id={'ForwardButton'}><ForwardIcon/></div>
                    <div id={'BackwardButton'}><BackwardIcon/></div>
                    <div id={'LeftButton'}><LeftIcon/></div>
                    <div id={'RightButton'}><RightIcon/></div>
                </Controls>
            )}


            <Leva hidden={!queryParameters.get('debug')}/>
            <Canvas id="main"
                    dpr={[PixelRatio.h, PixelRatio.w]}
                    style={{background: "#58ada1"}}
                    shadows={shadows}>

                <Suspense fallback={<LoaderScreen/>}>
                    <Physics gravity={[0, -2.7, 0]} broadphase="Naive" contactEquationRelaxation={4} friction={1e-3}
                             allowSleep>
                        <ColliderBox position={[-1, 0.5, 6.3]} scale={[0.6, 1, 0.6]} rotation={[0, -Math.PI / 2.7, 0]}/>
                        <ColliderBox position={[3, 0.1, -9]} scale={[8, 1, 0.6]} rotation={[0, 0, 0]}/>
                        <ColliderSphere position={[2.9, 0.5, 1.6]} scale={[1, 1, 1]} rotation={[0, -Math.PI / 2.7, 0]}/>

                        <TriggerBoxContainer isMobile={isMobile} position={[1, 0.3, 6.4]} number={0}/>
                        <TriggerBoxContainer isMobile={isMobile} position={[5, 0.3, 0.4]} number={1}/>
                        <TriggerBoxContainer isMobile={isMobile} position={[-3.5, 0.3, 2]} number={2}/>

                        <ThreeTextScene/>
                        <BillboardScene isMobile={isMobile}/>
                        <Ground/>

                        <AddLightContainer isMobile={isMobile}/>
                        <Track/>
                        <PerspectiveCamera makeDefault position={[-6, 3.9, 6.21]} fov={50} near={1} far={20}/>
                        <CarContainer isMobile={isMobile} controls={controls} setControls={setControls}/>
                    </Physics>
                    <Stats/>
                </Suspense>
            </Canvas>
        </>
    )
}

export default GamePage;
