import * as THREE from "three";
import {useFrame} from "@react-three/fiber";



let dayPass = false;
let night = false;

function AddLight(props) {
    const {isMobile} = props;

    //#edd59e
    let count = 0;
    const directionalLight = new THREE.SpotLight('white', 1);
    directionalLight.name = 'sun';
    directionalLight.position.y = 16
    directionalLight.castShadow = !isMobile;
    directionalLight.angle = 1;
    directionalLight.penumbra = 0.5;
    directionalLight.shadow.mapSize.width = isMobile ? 1024 : 4096;
    directionalLight.shadow.mapSize.height = isMobile ? 1024 : 4096;
    directionalLight.shadow.radius = 16;
    directionalLight.position.x = -16;

    useFrame(() => {
if (!isMobile) {


    if (!dayPass) count++;

    if (count > 1000 && !night) {
        night = true;
        count = 0;
    }

    if (count > 500 && count < 600 && night) {
        night = false;
        count = 0;
    }


    if (!night) {
        if (directionalLight.position.y < 16) {
            directionalLight.position.y += 0.005;
            dayPass = true;
        } else {
            dayPass = false;
        }

    }
    if (night) {
        if (directionalLight.position.y > -0.5) {
            directionalLight.position.y -= 0.005;
            dayPass = true;
        } else {

            dayPass = false;
        }
    }
}

    })

    const nightLight = new THREE.DirectionalLight('#9fc5e8', 0.3);
    return (
        <>


            <primitive object={directionalLight}></primitive>
            {!isMobile && ( <primitive object={nightLight}></primitive> )}

        </>
    );
}

export default AddLight;
