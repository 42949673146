import styled from "styled-components";
import {useEffect, useState} from "react";
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useProgress} from "@react-three/drei";

const ControlsHud = styled.div`
  position: absolute;
  //bottom: 50%;
  right: auto;
  left: Calc(50% - 150px);
  z-index: 99999;
  //background: rgba(61, 61, 61, 0.7);
  background-color: #adafb2;
  color: black;
  font-family: 'Roboto', sans-serif;
  border-radius: 15px;
  //font-weight: bold;
  //line-height: 30px;
  padding: 5px;
  width: 295px;
  top: ${props => props.opacity}%;
  transition: 900ms;
  display: grid;
  grid-template-columns: 20% 70% 10%;

  .emoji {
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;
const CircleProgress = styled.span`


  width: ${props => props.width}px;
  position: absolute;
  z-index: 99999;
  bottom: 30%;
  left: 10%;
`
/*const CircleProgressBackground = styled.span`
  background-color: black;
  border-radius: 50%;
  width: ${props=>props.width}px;
  height: ${props=>props.width}px;
  color: white;


  position: absolute;
  z-index: 99999;
  bottom: Calc(30% + 15px);
  left: Calc(10% + 15px);

  .text {
    font-size: ${props=>props.width/5}px;
    font-weight: bold;

    line-height: 20px;
    font-family: 'Roboto Mono', monospace;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

`*/
const HudWrapper = styled.div`
  font-family: 'Roboto Mono', monospace;
  z-index: 99999;
  position: absolute;
  bottom: ${props => props.position}%;
  left: 2%;
  color: white;
  transition: 900ms;
  font-size: 36px;
  @media(max-width: 1440px){
    font-size: 16px;
  }
`


function Hud(props) {
    const [text, setText] = useState('Используйте WASD для управления фургоном');
    const [title, setTitle] = useState('Управление');
    const [hudPosition, setHudPosition] = useState(100);
    const {active, progress, errors, item, loaded, total} = useProgress()
    const [controlsOpacity, setControlsOpacity] = useState(-100)
    const [percent, setPercent] = useState(0);
    useEffect(() => {
        if (props.interactData.letterCount) {
            setPercent(
                Math.ceil(
                    (props.interactData.letterCount * 100) / 50
                )
            );
        }
    }, [setPercent, props.interactData.letterCount]);

    useEffect(() => {
        if (total === 26) {

            setTimeout(() => {
                setControlsOpacity(2)
            }, 1500);
            setTimeout(() => {
                setControlsOpacity(-100);

            }, 6500);
            setTimeout(() => {
                setText('Сбивайте надписи, чтобы заработать очки');
                setTitle('Задание');
                setControlsOpacity(2);
            }, 7000);
           /* setTimeout(() => {
                setHudPosition(-5)
            }, 10000)*/
            setTimeout(() => {
                setControlsOpacity(-100)
            }, 13000);
        }


    }, [total])

    return (
        <>
            <ControlsHud opacity={controlsOpacity}>
                <div className={'emoji'}>&#128665;</div>
                <div>
                    <div style={{fontWeight: 'bold'}}>{title}</div>
                    {text}</div>
                <div>
                    <div style={{color: '#7c7c7c', fontSize: '12px'}}>now</div>
                    <div>&#128075;</div>
                </div>
            </ControlsHud>
             <ControlsHud opacity={props.interactData.resetTipOpacity}>  <div className={'emoji'}>&#128665;</div>
                 <div>
                     <div style={{fontWeight: 'bold'}}>{title}</div>
                     Нажмите R для возвращения на трассу</div>
                 <div>
                     <div style={{color: '#7c7c7c', fontSize: '12px'}}>now</div>
                     <div>&#128075;</div>
                 </div></ControlsHud>
           {/* <HudWrapper position={hudPosition}>
                <img width={window.innerHeight / 5} src={'/cliq-watch.png'} alt={'11'}/>
                <CircleProgressBackground  width={window.innerHeight / 7.7}>
                    <div className={'text'}>
                        <center>ABC</center>
                        <br/>{props.interactData.letterCount}/50
                    </div>
                </CircleProgressBackground>
                <CircleProgress width={window.innerHeight / 6.8}>

                    <CircularProgressbarWithChildren

                        value={document.querySelector('#letterCount') ? (document.querySelector('#letterCount').innerHTML * 100)/50 : 0}

                        styles={buildStyles({
                            rotation: 0.5,
                            strokeLinecap: 'round',
                            textSize: '16px',
                            pathTransitionDuration: 0.1,
                            pathColor: `#5dcae6`,
                            textColor: 'white',
                            trailColor: `rgba(0, 74, 127, 0)`,
                        })}
                    >
                        <span style={{marginLeft:'8px',textAlign:'center', fontWeight:'bold',letterSpacing:'8px'}}>ABC <br/><span id={'letterCount'}>0</span>/50</span></CircularProgressbarWithChildren>
                </CircleProgress>
            </HudWrapper>*/}
        </>
    )
}


export default Hud;
