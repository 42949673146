import {useLoader} from "@react-three/fiber";
import {FontLoader} from "three/examples/jsm/loaders/FontLoader";
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry";
import * as THREE from "three";
import {MeshPhongMaterial} from "three";
import {useBox} from "@react-three/cannon";


function AddThreeText(props) {
    const font = useLoader(FontLoader, '/fonts/Exo_2_Regular.json');
    let scored = false;
    let count = 0;
    const [ref, api] = useBox(() => ({
        mass: 100,
        position: props.position,
        rotation: props.rotation,
        args: [0.1, 0.2, 0.1],

        onCollide: (e) => {
            if (e.body.name === 'car' && !scored) {
                switch (props.triggerNumber){
                    case 0:
                        if(!props.billboardData.activeBillboard[3]){
                            props.setActiveBillboard(3);
                            props.setModal(-window.innerHeight/2.4, 3, '5');
                        }
                        break
                    case 1:
                        if(!props.billboardData.activeBillboard[4]) {
                            props.setActiveBillboard(4);
                            props.setModal(-window.innerHeight/2.4, 4, 'auto');
                        }
                        break
                }
                api.position.set(props.position.x, 5, props.position.z)
           //     count = Number(document.querySelector('#letterCount').innerHTML);
             //   count++;

               // document.querySelector('#letterCount').innerHTML = count;
              //  props.setCount(1);
                scored = true;
                if(count === 50){
                    props.setModal(-5000, 4, 'auto');
                    props.setFinalModal('50')
                    props.setFinalVersion('second');
                }
            }
        },

    }));
    const geometry = new TextGeometry(props.text, {
        font: font,
        size: 0.15,
        height: 0.07
    });
    const textMesh = new THREE.Mesh(geometry, [
        new MeshPhongMaterial(({color: 0xFFFFFF, side: THREE.DoubleSide})),
        new MeshPhongMaterial(({color: 0xFFFFFF, side: THREE.DoubleSide})),
    ])
    textMesh.name = 'letter';
    textMesh.castShadow = true;

    return (
        <>

            <mesh ref={ref}  receiveShadow={true} castShadow={true}>
                <primitive rotation={props.meshRotation} position={[-0.05, 0.04, 0]} object={textMesh}/>
            </mesh>

        </>
    )
}

export default AddThreeText
