
/*const Wrapper = styled.div`
  position: absolute;
  transition: 1000ms;

  background-color: white;
  border-radius: 20px;
  width: 800px;
  height: 800px;
  right: calc(50% - 400px);
  top:10%;
  padding: 20px;
  z-index: 99999;
  opacity: ${props => props.opacity}
`;*/




function AvatarPage(props){

    /*return(
        <Wrapper opacity={props.interactData.avatarModal}><img alt={'sysdyn'} width={800} src={'/123.png'}/></Wrapper>
    )*/
}


export default AvatarPage;