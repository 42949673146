import { connect } from 'react-redux';


import AvatarPage from "./AvatarPage";

const mapStateToProps = (state) => {
    return {
        interactData: state.interactReducer,

    };
};
const mapDispatchToProps = () => {
    return {


    };
};

const AvatarPageContainer = connect(mapStateToProps, mapDispatchToProps)(AvatarPage);
export default AvatarPageContainer;
