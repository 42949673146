import { forwardRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useCylinder } from '@react-three/cannon'
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";

useGLTF.preload('/wheel-draco.glb')

const Wheel = forwardRef(({ radius = 0.7, leftSide, number, ...props }, ref) => {
    const garment = useLoader(
        GLTFLoader,
        "/models/Wheel_F.glb"
    );
    const position = [
        [0,-0.041,0],
        [0,-0.041,0],
        [0,-0.041,0],
        [0,-0.041,0]
    ]
    garment.scene.name = 'wheel'
    let tyresMaterial = new THREE.MeshPhongMaterial({
        color: '#3a363b'
    });
    let rimsMaterial = new THREE.MeshPhongMaterial({
        color: '#817884'
    });
    let cloneWheel = garment.scene.clone();
    cloneWheel.children[0].children[0].material = tyresMaterial;
    cloneWheel.children[0].children[1].material = rimsMaterial;
    cloneWheel.children[0].children[1].material.metalness = 1;
    cloneWheel.children[0].children[1].material.roughness = 1;

  useCylinder(() => ({ mass: 1, type: 'Kinematic', material: 'wheel', collisionFilterGroup: 0, args: [radius, radius, 0.5, 16], ...props }), ref)
  return (
    <group ref={ref} dispose={null}>
      <group scale={1} position={position[number]}>
        <mesh>
            <primitive rotation={[0,leftSide ? 0 : 180*Math.PI/180,0]} scale={4.5} object={cloneWheel}/>
        </mesh>

      </group>
    </group>
  )
})

export { Wheel }
